import moment from 'moment/moment';
import { DATETIME_FORMAT } from '@/utils/constants';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';

const MIN_CAMPAIGN_NAME_LENGTH = 8;
const MAX_CAMPAIGN_NAME_LENGTH = 64;
const isValidDateTime = (date) => {
  if (date && date.length < 1) {
    return false;
  }

  return moment(date, DATETIME_FORMAT, true).isValid();
};

const validateCampaign = {
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(MIN_CAMPAIGN_NAME_LENGTH),
        maxLength: maxLength(MAX_CAMPAIGN_NAME_LENGTH),
      },
      startDate: {
        required,
        isValidDateTime,
      },
      endDate: {
        required,
        isValidDateTime,
      },
    },
  },
};

export { validateCampaign, MAX_CAMPAIGN_NAME_LENGTH, MIN_CAMPAIGN_NAME_LENGTH };
