var render = function render(){var _vm=this,_c=_vm._self._c;return _c('KTCodePreview',{attrs:{"title":_vm.formTitle},scopedSlots:_vm._u([{key:"preview",fn:function(){return [_c('div',[_c('b-card',[_c('b-form',{staticClass:"full-width",on:{"submit":_vm.onSubmit}},[_c('b-form-group',{attrs:{"label":"Tên Campaign:","label-for":"input-campaign","description":"Điền campaign mà bạn muốn nhập tại đây"}},[_c('b-form-input',{attrs:{"type":"text","required":"","placeholder":"Điền tên Campaign","state":!_vm.$v.form.name.$dirty ? null : !_vm.$v.form.name.$anyError,"size":"sm"},on:{"blur":function($event){return _vm.$v.form.name.$touch()}},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.name"}}),(!_vm.$v.form.name.required)?_c('b-form-invalid-feedback',[_vm._v(" Bạn chưa nhập tên Campaign ")]):_vm._e(),(!_vm.$v.form.name.minLength)?_c('b-form-invalid-feedback',[_vm._v(" Tên Campaign của bạn cần dài hơn "+_vm._s(_vm.MIN_CAMPAIGN_NAME_LENGTH)+" ký tự ")]):_vm._e(),(!_vm.$v.form.name.maxLength)?_c('b-form-invalid-feedback',[_vm._v(" Tên Campaign của bạn cần ngắn hơn "+_vm._s(_vm.MAX_CAMPAIGN_NAME_LENGTH)+" ký tự ")]):_vm._e()],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{staticClass:"mb-1",attrs:{"id":"input-start-date","label":"Thời gian bắt đầu:","label-for":"input-start-date","state":!_vm.$v.form.startDate.$dirty
                    ? null
                    : !_vm.$v.form.startDate.$anyError}},[_c('date-picker',{class:`form-control ${
                      _vm.$v.form.startDate.$dirty && _vm.$v.form.startDate.$anyError
                        ? 'form-input-error'
                        : ''
                    }`,attrs:{"id":"input-start-date","placeholder":"Thời gian bắt đầu","config":_vm.dpConfigs.date,"required":"","autosuggest":"off","size":"sm"},nativeOn:{"blur":function($event){return _vm.$v.form.startDate.$touch()}},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.startDate"}})],1),(!_vm.$v.form.startDate.required)?_c('b-form-invalid-feedback',[_vm._v(" Bạn chưa nhập thời gian bắt đầu ")]):_vm._e(),(
                  _vm.$v.form.startDate.$model &&
                    !_vm.$v.form.startDate.isValidDateTime
                )?_c('b-form-invalid-feedback',[_vm._v(" Thời gian bắt đầu không hợp lệ ")]):_vm._e()],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{staticClass:"mb-1",attrs:{"id":"input-end-date","label":"Thời gian kết thúc","label-for":"input-end-date","state":!_vm.$v.form.endDate.$dirty ? null : !_vm.$v.form.endDate.$anyError}},[_c('date-picker',{class:`form-control ${
                      _vm.$v.form.endDate.$dirty && _vm.$v.form.endDate.$anyError
                        ? 'form-input-error'
                        : ''
                    }`,attrs:{"id":"input-end-date","placeholder":"Thời gian kết thúc","config":_vm.dpConfigs.date,"required":"","autosuggest":"off","size":"sm"},nativeOn:{"blur":function($event){return _vm.$v.form.endDate.$touch()}},model:{value:(_vm.form.endDate),callback:function ($$v) {_vm.$set(_vm.form, "endDate", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.endDate"}})],1),(!_vm.$v.form.endDate.required)?_c('b-form-invalid-feedback',[_vm._v(" Bạn chưa nhập thời gian kết thúc ")]):_vm._e(),(
                  _vm.$v.form.endDate.$model && !_vm.$v.form.endDate.isValidDateTime
                )?_c('b-form-invalid-feedback',[_vm._v(" Thời gian kết thúc không hợp lệ ")]):_vm._e()],1)],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":!!_vm.$v.$invalid || _vm.isSubmitting},on:{"click":_vm.onSubmit}},[_vm._v(" Lưu ")])],1)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }